import {
  get,
  post
} from '../utils/http';
/** ***************辅导员工作计划**********************/
/**
 * 辅导员工作计划分页
 * @param {*} data
 * @returns
 */
function getgzjhpagelist(data) {
   return get('/fdy/SY_FDY_GZJH/GetPageList', data);
}
/**
 * 获取单条数据
 * @param {*} data
 * @returns
 */
function getgzjhformdata(data) {
  return get('/fdy/SY_FDY_GZJH/GetFormData', data);
}
/**
 * 删除数据
 * @param {*} data id
 * @returns
 */
function delgzjh(data) {
  return post('/fdy/SY_FDY_GZJH/Delete', data);
}
/**
 * 保存
 * @param {object} data 表单数据
 * @returns
 */
function savegzjh(data) {
  return post('/fdy/SY_FDY_GZJH/Save', data);
}
/**
 * 归档
 * @param {object} data 表单数据
 * @returns
 */
function guidanggzjh(data) {
  return post('/fdy/SY_FDY_GZJH/guidang', data);
}
/** ***************辅导员工宿舍工作记录**********************/
/**
 * 宿舍工作记录
 * @param {*} data
 * @returns
 */
function getroompagelist(data) {
  return get('/fdy/SY_FDY_XSSSGZJL/GetPageList', data);
}
/**
 * 获取单条数据
 * @param {*} data
 * @returns
 */
function getroomformdata(data) {
  return get('/fdy/SY_FDY_XSSSGZJL/GetFormData', data);
}
/**
 * 删除数据
 * @param {*} data id
 * @returns
 */
function delroom(data) {
  return post('/fdy/SY_FDY_XSSSGZJL/Delete', data);
}
/**
 * 保存
 * @param {object} data 表单数据
 * @returns
 */
function saveroom(data) {
  return post('/fdy/SY_FDY_XSSSGZJL/Save', data);
}
/**
 * 归档
 * @param {object} data 表单数据
 * @returns
 */
function guidangroom(data) {
  return post('/fdy/SY_FDY_XSSSGZJL/guidang', data);
}
/** ***************心理辅导工作记录**********************/
/**
 * 宿舍工作记录
 * @param {*} data
 * @returns
 */
function get_xlfd_pagelist(data) {
  return get('/fdy/SY_FDY_XLFD/GetPageList', data);
}
/**
 * 获取单条数据
 * @param {*} data
 * @returns
 */
function get_xlfd_formdata(data) {
  return get('/fdy/SY_FDY_XLFD/GetFormData', data);
}
/**
 * 删除数据
 * @param {*} data id
 * @returns
 */
function del_xlfd(data) {
  return post('/fdy/SY_FDY_XLFD/Delete', data);
}
/**
 * 保存
 * @param {object} data 表单数据
 * @returns
 */
function save_xlfd(data) {
  return post('/fdy/SY_FDY_XLFD/Save', data);
}
/**
 * 归档
 * @param {object} data 表单数据
 * @returns
 */
function guidang_xlfd(data) {
  return post('/fdy/SY_FDY_XLFD/guidang', data);
}

/** ***************家长联系工作记录**********************/
/**
 * 宿舍工作记录
 * @param {*} data
 * @returns
 */
function get_jzlx_pagelist(data) {
  return get('/fdy/SY_FDY_JZLX/GetPageList', data);
}
/**
 * 获取单条数据
 * @param {*} data
 * @returns
 */
function get_jzlx_formdata(data) {
  return get('/fdy/SY_FDY_JZLX/GetFormData', data);
}
/**
 * 删除数据
 * @param {*} data id
 * @returns
 */
function del_jzlx(data) {
  return post('/fdy/SY_FDY_JZLX/Delete', data);
}
/**
 * 保存
 * @param {object} data 表单数据
 * @returns
 */
function save_jzlx(data) {
  return post('/fdy/SY_FDY_JZLX/Save', data);
}
/**
 * 归档
 * @param {object} data 表单数据
 * @returns
 */
function guidang_jzlx(data) {
  return post('/fdy/SY_FDY_JZLX/guidang', data);
}
/** ***************减分登记**********************/
/**
 * 宿舍工作记录
 * @param {*} data
 * @returns
 */
function get_jfdj_pagelist(data) {
  return get('/fdy/SY_FDY_ZHCPJFDJ/GetPageList', data);
}
/**
 * 获取单条数据
 * @param {*} data
 * @returns
 */
function get_jfdj_formdata(data) {
  return get('/fdy/SY_FDY_ZHCPJFDJ/GetFormData', data);
}
/**
 * 删除数据
 * @param {*} data id
 * @returns
 */
function del_jfdj(data) {
  return post('/fdy/SY_FDY_ZHCPJFDJ/Delete', data);
}
/**
 * 保存
 * @param {object} data 表单数据
 * @returns
 */
function save_jfdj(data) {
  return post('/fdy/SY_FDY_ZHCPJFDJ/Save', data);
}
/**
 * 归档
 * @param {object} data 表单数据
 * @returns
 */
function guidang_jfdj(data) {
  return post('/fdy/SY_FDY_ZHCPJFDJ/guidang', data);
}
/** ***************帮扶记录**********************/
/**
 * 宿舍工作记录
 * @param {*} data
 * @returns
 */
function get_bf_pagelist(data) {
  return get('/fdy/SY_FDY_BFJL/GetPageList', data);
}
/**
 * 获取单条数据
 * @param {*} data
 * @returns
 */
function get_bf_formdata(data) {
  return get('/fdy/SY_FDY_BFJL/GetFormData', data);
}
/**
 * 删除数据
 * @param {*} data id
 * @returns
 */
function del_bf(data) {
  return post('/fdy/SY_FDY_BFJL/Delete', data);
}
/**
 * 保存
 * @param {object} data 表单数据
 * @returns
 */
function save_bf(data) {
  return post('/fdy/SY_FDY_BFJL/Save', data);
}
/**
 * 归档
 * @param {object} data 表单数据
 * @returns
 */
function guidang_bf(data) {
  return post('/fdy/SY_FDY_BFJL/guidang', data);
}
export default {
    getgzjhpagelist,
    getgzjhformdata,
    delgzjh,
    savegzjh,
    guidanggzjh,
    // 宿舍工作记录
    getroompagelist,
    getroomformdata,
    delroom,
    saveroom,
    guidangroom,
    // 心理辅导
    get_xlfd_pagelist,
    get_xlfd_formdata,
    del_xlfd,
    save_xlfd,
    guidang_xlfd,
    // 家长联系
    get_jzlx_pagelist,
    get_jzlx_formdata,
    del_jzlx,
    save_jzlx,
    guidang_jzlx,
    // 减分登记
    get_jfdj_pagelist,
    get_jfdj_formdata,
    del_jfdj,
    save_jfdj,
    guidang_jfdj,
    // 帮扶记录
    get_bf_pagelist,
    get_bf_formdata,
    del_bf,
    save_bf,
    guidang_bf,
}
